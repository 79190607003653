<template>
  <router-link
    class="mobile-menu-item"
    :to="route"
  >
    <div
      v-if="isSelected"
      class="is-selected"
    />
    <img
      :src="icon"
      alt="dashboard"
    >
  </router-link>
</template>

<script>
export default {
  name: 'MobileMenuItem',
  props: {
    route: {
      type: String, required: true
    },
    icon: {
      type: String, required: true
    }
  },
  computed: {
    isSelected () {
      return this.$route.path === this.route
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.mobile-menu-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 45px;

  .is-selected {
    position: absolute;
    top: 0;
    height: 3px;
    width: 80%;
    background-color: $color-menu-selected-item;
  }

  img {
    height: 23px;
    width: 23px;
  }
}
</style>
