<template>
  <div class="menu">
    <div class="item">
      <router-link to="/">
        <img
          class="root-logo"
          src="@/assets/logo_CaDyCo_white.png"
          alt="CaDyCo logo"
        >
      </router-link>
    </div>

    <div class="item">
      <div
        class="option"
        @click="getAccountDetails"
      >
        <img
          class="user-picture"
          :src="userPicture"
          alt="user avatar"
          data-test="user-picture"
        >
        <span>{{ $t('SideMenu.myAccount') }}</span>
      </div>
      <div
        class="option"
        @click="logout"
      >
        <img
          src="@/assets/menu/menu_deco.svg"
          alt="Déconnection"
        >
        <span>{{ $t('SideMenu.logout') }}</span>
      </div>
    </div>

    <SearchBar />
  </div>
</template>

<script>
import SearchBar from '@components/Search/SearchBar'
export default {
  name: 'MobileMenuExtension',
  components: { SearchBar },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    userPicture () {
      if (this.user) {
        return this.user.picture
      } else {
        return undefined
      }
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/')
      })
    },
    getAccountDetails () {
      this.$router.push('/account')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.menu {
  padding: 20px 5px;
  background-color: $color-menu-bg;

  .item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .root-logo {
    width: 109px;
    height: 32px;
  }

  .option {
    display: flex;
    align-items: center;
    color: $color-cadyco-mobile-menu-text;
    font-size: 0.75em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;

    img {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      margin-right: 6px;
    }
  }
}

</style>
