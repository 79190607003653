<template>
  <div class="mobile-menu">
    <MobileMenuItem
      route="/dashboard"
      :icon="require('@/assets/menu/menu_dashboard.svg')"
    />
    <MobileMenuItem
      route="/portfolio"
      :icon="require('@/assets/menu/menu_portfolio.svg')"
    />
    <MobileMenuItem
      route="/messaging"
      :icon="require('@/assets/menu/menu_messaging.svg')"
    />
    <MobileMenuItem
      route="/communities"
      :icon="require('@/assets/menu/menu_commu.svg')"
    />
    <MobileMenuItem
      route="/contacts"
      :icon="require('@/assets/menu/menu_contacts.svg')"
    />
    <MobileMenuItem
      route="/catalog"
      :icon="require('@/assets/menu/menu_indicators.svg')"
    />
    <MobileMenuItem
      v-if="isAdministrator || isCadycoAdministrator"
      route="/catalogmanager"
      :icon="require('@/assets/menu/menu_catalog.svg')"
    />
    <div class="extend-container">
      <img
        v-if="isMobileMenuDisplayed"
        class="cross"
        src="@/assets/cross_white.png"
        alt="collapse mobile"
        @click.stop="isMobileMenuDisplayed = false"
      >
      <img
        v-else
        class="options"
        src="@assets/mobile-options.png"
        alt="extend menu"
        @click.stop="isMobileMenuDisplayed = true"
      >
    </div>

    <Transition name="slide-fade">
      <MobileMenuExtension
        v-if="isMobileMenuDisplayed"
        class="mobile-menu-extension"
      />
    </Transition>
  </div>
</template>

<script>
import MobileMenuItem from '@components/MobileMenu/MobileMenuItem'
import MobileMenuExtension from '@components/MobileMenu/MobileMenuExtension'
export default {
  name: 'MobileMenu',
  components: { MobileMenuExtension, MobileMenuItem },
  data () {
    return {
      isMobileMenuDisplayed: false
    }
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    isAdministrator () {
      if (this.user) {
        return this.user.isAdministrator
      }
      return false
    },
    isCadycoAdministrator () {
      if (this.user) {
        return this.user.isCadycoAdministrator
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.mobile-menu {
  position: relative;
  height: $mobile-menu-height;
  width: 100%;
  padding: 0 1em;
  z-index: $mobile-menu-z-index;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-menu-bg;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.1);
}

.extend-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20px;

  .cross {
    height: 12px;
  }
  .options {
    height: 23px;
  }
}

.mobile-menu-extension {
  position: absolute;
  bottom: $mobile-menu-height;
  left: 0;
  width: 100%;
}

.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter-from, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100%);
  opacity: 0.5;
}
</style>
